<template>
  <div>
    <!-- CAROUSEL-->
    <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide  
                v-if="resources!=null"              
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row>
    <div>
      <br>
        <!-- <h1 class="titles">
          
          <span style="color: #d82125">T</span>
          <span style="color: #d02175">r</span>
          <span style="color: #f6c921">a</span>
          <span style="color: #d02175">n</span>
          <span style="color: #4d944a">s</span>
          <span style="color: #07aaba">p</span>
          <span style="color: #d82125">a</span>
          <span style="color: #d02175">r</span>
          <span style="color: #f6c921">e</span>
          <span style="color: #d02175">n</span>
          <span style="color: #07aaba">c</span>
          <span style="color: #4d944a">i</span>
          <span style="color: #d82125">a</span>
          
        </h1> -->

         <h1 class="titles mt-3 ">
          <span class="c-shadow-sm mt-3" style="color: #8b8c8c; padding:15px;">Transparencia</span>
        </h1>
    </div>
    <!-- CARDS-->
    <b-row align-v="center" align-h="center" class="mt-5">


      <b-col cols="11" md="5" lg="3">
        <b-card class=" c-shadow-sm" style="margin-top:40px; margin-bottom:60px; min-height:160px;" >
          <b-row align="center" justify="center">
            <b-col>
            <div class="img-supposition-rounded">
              <div :style="iconBall"> 
                <img width="45px;" height="45px;" style="margin-top:25px;" :src="require('../assets/palacio.png')" />
              </div>
            </div>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <a href="https://www.ipomex.org.mx/ipo3/lgt/indice/TEOTIHUACAN.web" target="_blank">
                <b-img src="https://teotihuacan.gob.mx/files/imagenes/ipomex.png"></b-img>
              </a>
            </b-col>
          </b-row>  
          <b-row><b-col class="mb-1"><b-button pill size="sm" variant="secondary"><a style="color:white;" href="https://www.ipomex.org.mx/ipo3/lgt/indice/TEOTIHUACAN.web" target="_blank"> Ir al Sitio </a><b-icon icon="arrow-right-circle-fill"/></b-button></b-col></b-row>
        </b-card>
      </b-col>

      

      <b-col cols="11" md="5" lg="3">
        <b-card class=" c-shadow-sm" style="margin-top:40px; margin-bottom:60px; min-height:160px;" >
          <b-row align="center" justify="center">
            <b-col>
            <div class="img-supposition-rounded">
              <div :style="iconBall"> 
                <img width="45px;" height="45px;" style="margin-top:25px;" :src="require('../assets/palacio.png')" />
              </div>
            </div>
            </b-col>
          </b-row>
         <b-row class="mt-5 mb-2">
            <b-col>
              <a href="https://www.saimex.org.mx/saimex/ciudadano/login.page" target="_blank">
                <b-img src="https://teotihuacan.gob.mx/files/imagenes/saimex.png"></b-img>
              </a>
            </b-col>
          </b-row>  
          <b-row><b-col class="mb-1"><b-button pill size="sm" variant="secondary"><a style="color:white;" href="https://www.saimex.org.mx/saimex/ciudadano/login.page" target="_blank"> Ir al Sitio </a><b-icon icon="arrow-right-circle-fill"/></b-button></b-col></b-row>
        </b-card>
      </b-col>

      <b-col cols="11" md="5" lg="3">
        <b-card class=" c-shadow-sm" style="margin-top:40px; margin-bottom:60px; min-height:160px;" >
          <b-row align="center" justify="center">
            <b-col>
            <div class="img-supposition-rounded">
              <div :style="iconBall"> 
                <img width="45px;" height="45px;" style="margin-top:25px;" :src="require('../assets/palacio.png')" />
              </div>
            </div>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col >
              <a href="https://sarcoem.org.mx/sarcoem/ciudadano/login.page" target="_blank">
                <b-img src="https://teotihuacan.gob.mx/files/imagenes/sarcoem.jpg"></b-img>
              </a>
            </b-col>
          </b-row>   
          <b-row><b-col class="mb-1"><b-button pill size="sm" variant="secondary"><a style="color:white;" href="https://sarcoem.org.mx/sarcoem/ciudadano/login.page" target="_blank"> Ir al Sitio </a><b-icon icon="arrow-right-circle-fill"/></b-button></b-col></b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-v="center" align-h="center" class="mt-5">
      <b-col cols="11" md="5" lg="3">
        <b-card class=" c-shadow-sm" style="margin-top:40px; margin-bottom:60px; min-height:180px;" >
          <b-row align="center" justify="center">
            <b-col>
            <div class="img-supposition-rounded">
              <div :style="iconBall"> 
                <img width="45px;" height="45px;" style="margin-top:25px;" :src="require('../assets/palacio.png')" />
              </div>
            </div>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col >
              <a href="https://www.plataformadetransparencia.org.mx/" target="_blank" >
                <b-img src="https://teotihuacan.gob.mx/files/imagenes/pnt.jpg"></b-img>
              </a>
            </b-col>
          </b-row>   
          <b-row><b-col class="mt-1 mb-1"><b-button pill size="sm" variant="secondary"><a style="color:white;" href="https://www.plataformadetransparencia.org.mx/" target="_blank"> Ir al Sitio </a><b-icon icon="arrow-right-circle-fill"/></b-button></b-col></b-row>
        </b-card>
      </b-col>

      <b-col cols="11" md="5" lg="3">
        <b-card class=" c-shadow-sm" style="margin-top:40px; margin-bottom:60px; min-height:200px;" >
          <b-row align="center" justify="center">
            <b-col>
            <div class="img-supposition-rounded">
              <div :style="iconBall"> 
                <img width="45px;" height="45px;" style="margin-top:25px;" :src="require('../assets/palacio.png')" />
              </div>
            </div>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col >
              <a href="https://www.transparenciaestadodemexico.org.mx/denciu/denuncia/insert.page" target="_blank">
                <b-img src="https://teotihuacan.gob.mx/files/imagenes/denuncias.png"></b-img>
              </a>
            </b-col>
          </b-row>   
          <b-row><b-col class="mb-1"><b-button pill size="sm" variant="secondary"><a style="color:white;" href="https://www.transparenciaestadodemexico.org.mx/denciu/denuncia/insert.page" target="_blank"> Ir al Sitio </a><b-icon icon="arrow-right-circle-fill"/></b-button></b-col></b-row>
        </b-card>
      </b-col>

      <b-col cols="11" md="5" lg="3">
        <b-card class=" c-shadow-sm" style="margin-top:40px; margin-bottom:60px; min-height:200px;" >
          <b-row align="center" justify="center">
            <b-col>
            <div class="img-supposition-rounded">
              <div :style="iconBall"> 
                <img width="45px;" height="45px;" style="margin-top:25px;" :src="require('../assets/palacio.png')" />
              </div>
            </div>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col >
              <a href="https://teotihuacan.gob.mx/files/transparencia/Transaparencia%20proactiva/" target="_blank">
                <b-img src="https://teotihuacan.gob.mx/files/imagenes/transparenciapro.jpg"></b-img>
              </a>
            </b-col>
          </b-row>   
          <b-row><b-col class="mb-1"><b-button pill size="sm" variant="secondary"><a style="color:white;" href="https://teotihuacan.gob.mx/files/transparencia/Transaparencia%20proactiva/" target="_blank"> Ir al Sitio </a><b-icon icon="arrow-right-circle-fill"/></b-button></b-col></b-row>
        </b-card>
      </b-col>

      
    </b-row>

     <b-row align-v="center" align-h="center" class="mt-5">
      <b-col cols="11" md="5" lg="3">
          <b-card class=" c-shadow-sm" style="margin-top:40px; margin-bottom:60px; min-height:160px;" >
            <b-row align="center" justify="center">
              <b-col>
              <div class="img-supposition-rounded">
                <div :style="iconBall"> 
                  <img width="45px;" height="45px;" style="margin-top:25px;" :src="require('../assets/palacio.png')" />
                </div>
              </div>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col>
                <a href="https://infoem2.ipomex.org.mx/ipomex/#/obligaciones/183" target="_blank">
                  <b-img src="https://teotihuacan.gob.mx/files/imagenes/ipomex40.jpeg"></b-img>
                </a>
              </b-col>
            </b-row>  
            <b-row><b-col class="mb-1"><b-button pill size="sm" variant="secondary"><a style="color:white;" href="https://www.ipomex.org.mx/ipo3/lgt/indice/TEOTIHUACAN.web" target="_blank"> Ir al Sitio </a><b-icon icon="arrow-right-circle-fill"/></b-button></b-col></b-row>
          </b-card>
        </b-col>
     </b-row>

    <div>
     <h1 class="titles mt-5 ">
          <span class="c-shadow-sm mt-3" style="color: #8b8c8c; padding:15px;">Procedimiento para presentar una denuncia</span>
        </h1>
    </div>
    <!-- CARDS-->
    <b-row align-v="center" align-h="center" class="mt-5 mb-5">
      <b-col cols="10" md="3" lg="2">
        <b-row>
          <b-col >
            <a href="https://infoem.org.mx/es/contenido/transparencia#transDenuncia" target="_blank">
              <b-img  class="mb-3" fluid src="https://teotihuacan.gob.mx/files/imagenes/denuncia_teotihuacan.png"></b-img>
            </a>
           
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div>
     <h1 class="titles mt-5 ">
          <span class="c-shadow-sm mt-3" style="color: #8b8c8c; padding:15px;">Datos Abiertos</span>
        </h1>
    </div>
    <!-- CARDS-->
    <b-row align-v="center" align-h="center" class="mt-5">
      <b-col cols="11" md="5" lg="8">
        <b-row>
          <b-col v-for="dato in datosAbiertos" :key="dato.icon" >
            <a :href="dato.url" target="_blank">
              <b-img width="200%" :src="dato.icon"></b-img>
            </a>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <h3 class="titles mt-5 " >
        <span class="c-shadow-sm" style="color: #8b8c8c; padding:15px;">Ley General de Contabilidad Gubernamental</span>
    </h3>
    <b-row class="mt-5" align-h="center" v-if="resources!=null">
        <b-col cols="11" md="10" lg="8" class="mb-5">
            <div class="accordion" role="tablist">
              
               <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-20242 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Segundo Trimestre 2024</b-button>
                </b-card-header>                
                <b-collapse id="accordion-20242"  accordion="my-accordion" role="tabpanel">
                    <b-card-body style="text-align: left">
                        <b-card-text >
                          <a href="https://teotihuacan.gob.mx/files/contabilidad/Segundo%20Trimestre%202024/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Información Financiera</a>
                        </b-card-text>
                        <!-- <b-card-text >
                          <a href="https://teotihuacan.gob.mx/files/contabilidad/Primer%20Trimestre%202022/TITULO%20V/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                        </b-card-text> -->
                    </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-20241 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Primer Trimestre 2024</b-button>
                </b-card-header>                
                <b-collapse id="accordion-20241"  accordion="my-accordion" role="tabpanel">
                    <b-card-body style="text-align: left">
                        <b-card-text >
                          <a href="https://teotihuacan.gob.mx/files/contabilidad/Primer%20Trimestre%202024/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Información Financiera</a>
                        </b-card-text>
                        <!-- <b-card-text >
                          <a href="https://teotihuacan.gob.mx/files/contabilidad/Primer%20Trimestre%202022/TITULO%20V/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                        </b-card-text> -->
                    </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-cp23 style="width:100%;   text-align:left; "><b-icon icon="files"/> Cuenta Pública 2023</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-cp23"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Cuenta%20P%C3%BAblica%202023/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuenta Pública 2023</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>                
                </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-20234 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Cuarto Trimestre 2023</b-button>
                </b-card-header>                
                <b-collapse id="accordion-20234"  accordion="my-accordion" role="tabpanel">
                    <b-card-body style="text-align: left">
                        <b-card-text >
                          <a href="https://teotihuacan.gob.mx/files/contabilidad/Cuarto%20Trimestre%202023/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Información Financiera</a>
                        </b-card-text>
                        <!-- <b-card-text >
                          <a href="https://teotihuacan.gob.mx/files/contabilidad/Primer%20Trimestre%202022/TITULO%20V/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                        </b-card-text> -->
                    </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-20233 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Tercer Trimestre 2023</b-button>
                </b-card-header>                
                <b-collapse id="accordion-20233"  accordion="my-accordion" role="tabpanel">
                    <b-card-body style="text-align: left">
                        <b-card-text >
                          <a href="https://teotihuacan.gob.mx/files/contabilidad/Tercer%20Trimestre%202023/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Información Financiera</a>
                        </b-card-text>
                        <!-- <b-card-text >
                          <a href="https://teotihuacan.gob.mx/files/contabilidad/Primer%20Trimestre%202022/TITULO%20V/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                        </b-card-text> -->
                    </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-20232 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Segundo Trimestre 2023</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-20232"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Segundo%20Trimestre%202023/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Información Financiera</a>
                          </b-card-text>
                          <!-- <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Primer%20Trimestre%202022/TITULO%20V/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text> -->
                      </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-20231 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Primer Trimestre 2023</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-20231"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Primer%20Trimestre%202023" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Información Financiera</a>
                          </b-card-text>
                          <!-- <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Primer%20Trimestre%202022/TITULO%20V/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text> -->
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-2023Otra style="width:100%;   text-align:left; "><b-icon icon="files"/> Otra Información Financiera 2023</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-2023Otra"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/OTRA%20INFORMACION%202023" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Información Financiera</a>
                          </b-card-text>
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-cp22 style="width:100%;   text-align:left; "><b-icon icon="files"/> Cuenta Pública 2022</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-cp22"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Cuenta%20P%C3%BAblica%202022/CUENTA%20PUBLICA%202022%20TEOTIHUACAN.pdf" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuenta Pública 2022</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>                
                </b-card>
                
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-0 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Primer Trimestre 2022</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-0"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Primer%20Trimestre%202022/TITULO%20IV%201%20de%202/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Primer%20Trimestre%202022/TITULO%20V/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-02 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Segundo Trimestre 2022</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-02"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Segundo%20Trimestre%202022/TITULO%20IV/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Segundo%20Trimestre%202022/TITULO%20V/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text> 
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-03 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Tercer Trimestre 2022</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-03"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Tercer%20Trimestre%202022/TITULO%20IV/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Tercer%20Trimestre%202022/TITULO%20V/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text> 
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-4 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Cuarto Trimestre 2022</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-4"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Cuarto%20Trimestre%202022/TITULO%20IV/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text> 
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Cuarto%20Trimestre%202022/TITULO%20V/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text> 
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-cp style="width:100%;   text-align:left; "><b-icon icon="files"/> Cuenta Pública 2021</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-cp"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Cuenta%20Publica%202021/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuenta Pública 2021</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>                
                </b-card>


                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-1 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera 2021</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-1"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%201ER%20TRIM%202021/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Primer Trimestre</a>
                          </b-card-text>
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%202o%20TRIM%202021/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Segundo Trimestre</a>
                          </b-card-text>
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%203ER%20TRIM%202021/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Tercer Trimestre</a>
                          </b-card-text>
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%204o%20TRIM%202021/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuarto Trimestre</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>                
                </b-card>

                 <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-cp20 style="width:100%;   text-align:left; "><b-icon icon="files"/> Cuenta Pública 2020</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-cp20"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Cuenta%20P%c3%bablica%202020/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuenta Pública 2020</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>                
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-2 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera 2020</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%201ER%20TRIM%202020/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Primer Trimestre</a>
                          </b-card-text>
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%202o%20TRIM%202020/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Segundo Trimestre</a>
                          </b-card-text>
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%203ER%20TRIM%202020/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Tercer Trimestre</a>
                          </b-card-text>
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%204o%20TRIM%202020/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuarto Trimestre</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>                
                </b-card>


                 <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-cp19 style="width:100%;   text-align:left; "><b-icon icon="files"/> Cuenta Pública 2019</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-cp19"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Cuenta%20P%c3%bablica%202019/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuenta Pública 2019</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>                
                </b-card>


                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-i3 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera 2019</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-i3"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%201ER%20TRIM%202019/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Primer Trimestre</a>
                          </b-card-text>
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%202o%20TRIM%202019/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Segundo Trimestre</a>
                          </b-card-text>
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%203ER%20TRIM%202019/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Tercer Trimestre</a>
                          </b-card-text>
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%204o%20TRIM%202019/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuarto Trimestre</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>                
                </b-card>

                 <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-cp18 style="width:100%;   text-align:left; "><b-icon icon="files"/> Cuenta Pública 2018</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-cp18"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/Cuenta%20P%c3%bablica%202018/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuenta Pública 2018</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>                
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-i4 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera 2018</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-i4"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%201ER%20TRIM%202018/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Primer Trimestre</a>
                          </b-card-text>
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%202o%20TRIM%202018/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Segundo Trimestre</a>
                          </b-card-text>
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%203ER%20TRIM%202018/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Tercer Trimestre</a>
                          </b-card-text>
                          <b-card-text>
                            <a href="https://teotihuacan.gob.mx/files/contabilidad/INFORMACION%20FINANCIERA%204o%20TRIM%202018/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuarto Trimestre</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>                
                </b-card>
                
                

            </div>
          </b-col>
        </b-row>

        <b-row align="center" align-h="center" class="mt-2">
          <b-col class="c-shadow-sm mt-5 mb-5" cols="11" md="4" lg="3">           
            <br>
            <h3 style="color:black;"><b-icon icon="file-text"/> <b>Edictos</b></h3>
            <br>
            <a href="https://teotihuacan.gob.mx/files/avisos/edictos" target="_blank">
              <b-button>Consultar <b-icon icon="arrow-right-circle"/></b-button>
              <br><br>
            </a>
          </b-col>
        </b-row>
  </div>
</template>
<script>
 import axios from "axios";

  export default {
    mounted: function(){
      var host = location.protocol + '//' + location.host;
      axios({
          method: 'get',
          url: host+'/files/transparencia.json',
      })
      .then(res => {
          this.resources = res.data;
      }).catch(err => {
        alert(err);  
      });
    },
    computed: {
      styleIconBall: function(){
        var style = this.iconBall;
        if(this.width <= 768){
          style=this.iconBallSm;
        }
        return style;
      },
      iconBall: function(){
        return " display: block; width: 100%; height: 100%; align-items: center; " +
        "background: linear-gradient(90deg, rgba(255,31,31,1) 0%, rgba(248,0,0,1) 100%);" +
        "border-radius: 50%; " +
        "box-shadow: inset -25px -25px 40px rgba(10,10,30,.4); " +
        "background-image: -webkit-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -moz-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -o-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -ms-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "position:absolute; left: 0px; top:0px; text-align:center; ";
      }
    },
    data() {
      return {
        resources:null,
        datosAbiertos:[
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Basesytransporte_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/TRANSPORTE.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Centros_de_Salud_Teotihuacan.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/SALUD.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Delegaciones_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/DELEGACIONES.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Esc_M-SUPERIOR%20_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/ESCUELAMSUP.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Esc_Preescolar_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/ESCUELAPREES.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Esc_Primarias_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/ESCUELAPRIM.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Esc_SUPERIOR%20_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/ESCUELASUP.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Esc_Secundarias_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/ESCUELASEC.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Escuelas_Otras_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/ESCUELASOTRAS.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Escuelas_Particulares_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/ESCUELASPART.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Fiestass_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/FIESTAS.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Hoteles_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/HOTELES.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Iglesias_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/IGLESIAS.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Instalaciones_deportivas_Teotihuac%c3%a1n.xls", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/INSTDEPORT.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_JuegodePelota_Hoteles_Teotihuac%c3%a1n%20(1).csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/JUEGOPELOTA.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Mercados_Tianguis_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/MERCTIANGUIS.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_MonumentosyPatrimonio_Teotihuac%c3%a1n%20(Autoguardado).csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/MONUMENTOS.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Restaurantes_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/RESTAURANTES.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Talleres_Artesanales_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/TALLERES.jpg"},
          {url:"https://teotihuacan.gob.mx/files/transparencia/Datos%20Abiertos/CSV_Temazcales_Teotihuac%c3%a1n.csv", icon:"https://teotihuacan.gob.mx/files/imagenes/iconos/TEMAZCALES.jpg"},
        ]
      }
    }
  }
</script>
